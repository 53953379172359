import React from 'react';

const classes = {
  heading:'font-semibold tracking-widest text-xl text-gray-900 leading-normal uppercase pb-4 text-left',
  content: 'flex-none text-lg text-black font-light md:flex-1 md:pl-20 md:pr-20 mb-4 leading-relaxed',
};

const Section = ({ title, children }) => {
  return (
    <React.Fragment>
      <h2 className={classes.heading}>{title}</h2>
      <div className={classes.content}>{children}</div>
    </React.Fragment> 
  );
};

export default Section;
