import { Link } from 'gatsby';
import React from 'react';

const classes = {
  container: 'mb-6',
  title: 'font-medium text-gray-700 pb-1',
  summary: 'text-lg text-black font-light',
};

const SummaryItem = ({ name, description, link = false, internal = false }) => {
  let linkContent;
  if (internal) {
    linkContent = <Link to={link}>{name}</Link>;
  } else {
    linkContent = <a href={link}>{name}</a>;
  }

  let seeMoreCareer;
  if (name === 'The Solution Stack' || name === 'Xerris Inc') {
    seeMoreCareer = <Link to="/career">
    <div className="text-lg text-black font-light hover:underline hover:text-black">
      ...see projects in Career
    </div>
  </Link>
  }

  let seeCertification;
  if (name === 'Certifications') {
    seeMoreCareer = <a href="https://www.credly.com/badges/ea66a14d-40aa-49ef-a82e-55b4f18aabc4">
    <div className="text-lg text-black font-light hover:underline hover:text-black">
      ...see certificate
    </div>
  </a>
  }

  return (
    <div className={classes.container}>
      <h3
        className={`${classes.title} ${
          link ? 'hover:underline hover:text-black' : ''
        }`}
      >
        {link ? linkContent : name}
      </h3>
      <p className={classes.summary}>{description}</p>
      {seeMoreCareer}
      {seeCertification}
    </div>
  );
};

export default SummaryItem;
