import React from 'react';

import Section from './Section';
import SummaryItem from '../SummaryItem';

const SkillSection = ({ skills }) => {
  return (
    <Section title="Skills">
      {skills.map((skill) => (
        <SummaryItem
          key={skill.title}
          name={skill.title}
          description={skill.summary}
        />
      ))}
    </Section>
  );
};

export default SkillSection;
