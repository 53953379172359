import { graphql } from 'gatsby';
import React from 'react';
import SEO from '../components/SEO';
import Profile from '../views/Profile';
//import '../css/index.css'

const Index = ({ data }) => {
  return (
    <React.Fragment>
      <SEO />
      <Profile metadata={data.site.siteMetadata} data={data}/>
    </React.Fragment>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        location
        description
        education
        about
        author
        github
        linkedin
        projects {
          title
          summary
          link
        }
        experience {
          title
          summary
          link
        }
        skills {
          title
          summary
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            summary
          }
        }
      }
    }
  }
`;
